import { Component, OnInit, inject } from '@angular/core';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { NgClass } from '@angular/common';
import { classRoomService } from '../../../shared/services/api/class-room.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { AppComponent } from '../../../app.component';
import { MilestoneService } from '../../../shared/services/api/milestone.service';
import { Store } from '@ngrx/store';
import { setStudentObject } from '../../../../state/student/student.actions';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from "../../../shared/pipe/hindi-number.pipe";
@Component({
  selector: 'app-class-room',
  standalone: true,
  providers: [ classRoomService, MilestoneService],
  templateUrl: './class-room.component.html',
  styleUrl: './class-room.component.css',
  imports: [
    NgClass,
    NavbarComponent,
    SpinnerComponent,
    AppComponent,
    FooterNavbarComponent,
    TranslateModule,
    HindiNumberPipe
]
})
export class ClassRoomComponent implements OnInit {
  private _classRoomService = inject(classRoomService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private store = inject(Store);
  private translate = inject(TranslateService);

  schoolName: string = 'Global International School';
  teacherName: string = 'Mr. John Doe';
  studentName: string = 'Jane Doe';
  result: any = [];
  students: any = [];
  totalStudents: number = 20;
  class_id: number = 1;
  loader: boolean = false;
  standard: string = "Classroom";
  division: string = "A";

  page = 1;
  loading = false;
  private readonly childsPerListLimit = 5;

  backToDash: boolean = true;

  goBackTo : string = 'dashboard';

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  // schoollogo :string;
  constructor() {

  }


  ngOnInit() {



    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.teacherName = String(this.route.snapshot.paramMap.get('teacher_name'));

    this.loadChildDetails();


    // Api call for getting the students in class room


  }

  loadChildDetails() {
    if (this.loading) return;
    this.loading = true;

    if (typeof window !== 'undefined' && window.localStorage) {
      this._classRoomService.getClassById(this.class_id).subscribe({
        next: (data) => {
          this.result = data;

          this.result.data.students.forEach((element: any) => {
            element.results = JSON.parse(element.results);
          });

          this.students.push(...this.result.data.students);
          this.page++;

          this.schoolName = this.result.data.school_name;
          this.class_id = this.result.data.class_id;
          this.standard = this.result.data.standard;
          this.division = this.result.data.division;
          this.totalStudents = this.students.length;
          
          this.loader = true;
        },
        error: (error: string) => {
          console.error('Error:', error);
        }
      });
    }


  }

  onScroll(): void {
    const container = document.querySelector('.studentsList') as HTMLElement;
    if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
      this.loadChildDetails();
    }
  }



  goToResult(
    student_id: number, 
    studentName: string,
    child_age: string,
    dob_month: number,
    dob_date: number ,
    dob_year: number
  ): void {
    this.router.navigate(['/result', this.class_id, student_id], { queryParams: { studentName: studentName, age: child_age, schoolName: this.schoolName, totalStudents: this.totalStudents, standard: this.standard, division: this.division, teacherName: this.teacherName , dob_month: dob_month, dob_date: dob_date, dob_year: dob_year} });
  }

  goToSubcats(
    categoryId: number, 
    cat_name: string,
    student_id: number, 
    studentName: string, 
    studentLastName: string,
    child_born_year: number, 
    child_born_month: number, 
    child_born_date: number, 
    child_age: string
  ): void {

    if (!studentLastName) {
      console.log("student last name is null: ", studentLastName);
    } else {
      studentName = studentName + " " + studentLastName;
    }

    const studentData = {
      studentName : studentName,
      age : child_age,
      class_id : this.class_id,
      student_id: student_id,
      dob_year: child_born_year,
      dob_month: child_born_month,
      dob_date: child_born_date,
      schoolName: this.schoolName,
      totalStudents: this.totalStudents,
      standard : this.standard,
      division: this.division,
      teacherName: this.teacherName,
      disableBack : false
    };
    
    this.store.dispatch(setStudentObject({ studentObject: studentData }))

    this.router.navigate(['/sub-categories', this.class_id, student_id, categoryId, cat_name], 
      { queryParams: studentData }
    );
  }

  /**
   * For  calculating the age we needed these 3 parameters 
   * @param birthYear 
   * @param birthMonth 
   * @param birthDate 
   * @returns 
   */

  calculateAge(birthYear: number, birthMonth: number, birthDate: number) {
    // For calculaitng  the age we needed the current date and birth date we are getting that from below two lines
    const currentDate = new Date();
    const birthDateObj = new Date(birthYear, birthMonth - 1, birthDate);

    // Thses  variables are stor ing the difference of birthdate and current date 
    // We will get the age with the help of thses variables 
    const yearsDiff = currentDate.getFullYear() - birthDateObj.getFullYear();
    const monthsDiff = currentDate.getMonth() - birthDateObj.getMonth();
    const daysDiff = currentDate.getDate() - birthDateObj.getDate();

    // for storing the age 
    let age = '';

    /**
     * If the month difference is less than 0 or have same month
     * Then we need to add 12 in month differen as it shows that the current month is the birthmonth of child
     * And birth date isn't come yet 
     */

    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {

      // as the birthday isn't come yet so wee need to subtract it with 1 
      let year = yearsDiff - 1;
      age = `${this.translate.instant(`num.${year}`)}${this.translate.instant('y')} `;
      if (monthsDiff < 0) {
        age += `${this.translate.instant(`num.${12 + monthsDiff}`)}${this.translate.instant('m')}`;
      }
    } else {
      // AS mention above if the birth date is passed in the current year we just need to assign both to age vaiable
      age = `${this.translate.instant(`num.${yearsDiff}`)}${this.translate.instant('y')} `;
      if (monthsDiff > 0) {
        age += ` ${this.translate.instant(`num.${monthsDiff}`)}${this.translate.instant('m')}`;
      }
    }
    return age;
  }

}