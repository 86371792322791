
@if (loader) {
  <div class="spinner">
    <app-navbar [teacherName]="teacherName" [standard]="standard" [division]="division" [totalStudents]="totalStudents" [goBackTo]="goBackTo"></app-navbar>
    <section class=" studentsList d-flex align-items-center justify-content-center mt-8 flex-column "  (scroll)="onScroll()">
      <!-- <div class="d-flex align-items-center flex-wrap justify-content-between w-75 mb-1">
      <p>{{ standard }} - {{division}}</p>
      <p>TOTAL Students - {{ totalStudents }}</p>
    </div> -->
    @for (student of students; track student; let i = $index) {
      <div class="studentCard">
        <div class="cardHeader border-bottom-0 p-2  d-flex align-items-center justify-content-between border-success"
          role="presentation"
          (click)="goToResult(student.id, student.child_name, calculateAge(student.child_born_year, student.child_born_month, student.child_born_date) , student.child_born_month, student.child_born_date, student.child_born_year)"
          [ngClass] = "{'cardHeader':i%2===0 , 'cardHeader1': i%2!==0 }" >
          <!-- Student name -->
          <span class="student-name-age p-2">{{ (i + 1) | hindiNumber }}. {{student.child_name}} {{student.child_last_name}}</span>
          <!-- Student age -->
          <span class="student-name-age p-2 d-flex align-items-center">{{calculateAge(student.child_born_year, student.child_born_month, student.child_born_date)}}
            <button class="resultButton flex-cent">
              <!-- Displaying resultbutton  -->
              <img id="resultIcon" src="../../../../assets/img/ResultIcon.png" alt="img" >
            </button>
          </span>
        </div>
        <div class=" text-success">
          <ul class="shortCatName-section">
            @for (cat of student.results; track cat) {
              <li role="presentation"
                (click)="goToSubcats(cat.cat_id,cat.cat_name,student.id,student.child_name, student.child_last_name, student.child_born_year, student.child_born_month, student.child_born_date, calculateAge(student.child_born_year, student.child_born_month, student.child_born_date))"
                class="circular_cat d-flex align-items-center justify-content-center rounded-circle me-2 col-sm-6 pb-0  shadow bg-body text-dark text-center"
                [ngClass]="{'pending': cat.completion === 'pending', 'completed': cat.completion === 'completed'}">
                {{cat.short_cat_name | translate}}
              </li>
            }
          </ul>
        </div>
      </div>
    }
  </section>
  <app-footer-navbar [class_id]="class_id"></app-footer-navbar>
</div>
} @else {
  <app-spinner></app-spinner>
}
