<app-navbar [navbarDisplay] = "navbarDisplay" [mode]="mode" [class_id]="class_id" [goBackTo]="goBackTo"></app-navbar>

<div class="parent-container">
  <div class="header-container" [ngClass]="{'basicProfile' : catName === 'Basic' , 'lightGreen' : averageResult >= 66 , 'lightRed' : averageResult <= 25 , 'lightYellow' : averageResult >= 26 && averageResult <= 65}">
    <div class="heading">
      <h4 style="margin-left: 0.7rem;">{{catName | translate}}</h4>
    </div>
    <div class="piechart">
      <div id="chartDiv"></div>
      <div class="score">
        <!-- <h6>{{'result.avg' | translate}}</h6> -->
        <h4 class="font-bold averageColor" style="margin: 0;">{{averageResult | hindiNumber}}%</h4>
      </div>
    </div>
  </div>

  <div class="subcat-container dFlex " [ngClass]="{'lightGreen' : averageResult>=66 , 'lightRed' : averageResult<=25 , 'lightYellow' : averageResult>=26 && averageResult<=65 , 'basicProfile' : catName==='Basic' }">
    <h5 style="margin-top: 0.7rem;">{{'subCatSummary.developmentAreas' | translate}}</h5>
    @for (obj of subcat_results; track obj) {
      <div class="catcard">
        <div class="upperCard">
          <p style="margin-left: 0.7rem; margin-top: 0.6rem;">{{obj['cat_name']}}</p>
          <p class="font-bold" style="margin-right: 0.7rem; margin-top: 0.6rem;">{{obj['score'] | hindiNumber}}%</p>
        </div>
        <div class="lowerCard">
          <div class="progressBar" [style.width.%]="obj['score']" [ngClass]="{'Yellow': obj['score']>=26 && obj['score']<=65 , 'Red': obj['score'] <= 25 , 'Green': obj['score'] >= 66}"></div>
          @if (obj['score'] >= 90) {
            <img src="../../../../../assets/img/star.png" alt="">
          }
        </div>
      </div>
    }
  </div>

  <div class="parents-engagement dFlex" [ngClass]="{'lightGreen' : averageResult>=66 , 'lightRed' : averageResult<=25 , 'lightYellow' : averageResult>=26 && averageResult<=65 , 'basicProfile' : catName==='Basic'}">
    <h5 style="margin-top: 0.7rem;">{{'subCatSummary.parentEng' | translate}}</h5>
    <!-- home-activities dropdown -->

    <div id="homeActivity" class="homeActivity">
      <div class="mainActivity" (click)="toggleGuidelines()">
        <p>{{'subCatSummary.guideLines' | translate}} </p>
        <div class="activitynumbers" >
          <p class="font-bold">{{ guidlinesDone | hindiNumber}}/{{ guidelines | hindiNumber}}</p>
          <img src="../../../../../assets/img/dropdown.png" alt="" [ngClass]="{'active': isGuidLinesExpaneded}">
        </div>
      </div>

      @if (isGuidLinesExpaneded) {
        <div class="expandabelDiv">
          @for (item of subcatSummary; track item; let i = $index) {
            <div [ngClass]="'subCatexpand subCatexpand-' + i">
              <p>{{(i + 1) | hindiNumber}}. {{item.subcat_name}}</p>
              <p>{{item.parentGuidlines.done | hindiNumber}}/{{item.parentGuidlines.total | hindiNumber}}</p>
            </div>
          }
        </div>
      }
    </div>

    <div id="homeActivity" class="homeActivity">
      <div class="mainActivity" (click)="toggleExpand()">
        <p>{{'subCatSummary.homeAct' | translate}} </p>
        <div class="activitynumbers" >
          <p class="font-bold">{{homeactivityDone | hindiNumber}}/{{homeactivities | hindiNumber}}</p>
          <img src="../../../../../assets/img/dropdown.png" alt="" [ngClass]="{'active': isActivityExpanded}">
        </div>
      </div>

      @if (isActivityExpanded) {
        <div class="expandabelDiv">
          @for (item of subcatSummary; track item; let i = $index) {
            <div [ngClass]="'subCatexpand subCatexpand-' + i">
              <p>{{(i + 1) | hindiNumber}}. {{item.subcat_name}}</p>
              <p>{{item.homeActivity.done | hindiNumber}}/{{item.homeActivity.total | hindiNumber}}</p>
            </div>
          }
        </div>
      }
    </div>

    <!-- worksheets dropdown -->
    <div id="workesheet" class="homeActivity">
      <div class="mainActivity" (click)="toggleWorksheetExpand()" >
        <p>{{'subCatSummary.worksheet' | translate}}</p>
        <div class="activitynumbers">
          <p class="font-bold">{{worksheetDone | hindiNumber}}/{{worksheet | hindiNumber}}</p>
          <img src="../../../../../assets/img/dropdown.png" alt="" [ngClass]="{'active': isWorksheetExpanded}">
        </div>

      </div>

      @if (isWorksheetExpanded) {
        <div class="expandabelDiv">
          @for (item of subcatSummary; track item; let i = $index) {
            <div [ngClass]="'subCatexpand subCatexpand-' + i">
              <p>{{(i + 1) | hindiNumber}}. {{item.subcat_name}}</p>
              <p>{{item.workSheet.done | hindiNumber}}/{{item.workSheet.total | hindiNumber}}</p>
            </div>
          }
        </div>
      }
    </div>

  </div>
</div>

<app-footer-navbar [class_id]="class_id"></app-footer-navbar>