@if(loader){
<section class="sec-portfolio">
    <app-navbar [schoolNavBar]="true" [navbarDisplay]="none"></app-navbar>
    <div class="portfolio flex-cent flex-col">
        <section class="my-portfolio info-card">
            <div class="student-info">
                <h2 class="heading">{{ 'portfolio.title' | translate}}</h2>
                <p class="holistic">{{ 'portfolio.holistic' | translate}}</p>
                <span> <span class="boldText">{{ studentName }}</span> | {{ age }}</span>
                <span class="boldText">{{ standard }} {{ division}}</span>
                <span>{{ 'portfolio.classTeacher' | translate}} : {{ teacherName }}</span>
                <span>{{ 'prentInvite.birthDate' | translate}}: {{ birthDate }}</span>
                <div class="heightWeight d-flex align-center just-start">
                    <span class="height">{{'Height' | translate}} : {{ height }}</span>
                    <span>{{'Weight' | translate}} : {{ weight }}</span>
                </div>
                <span>{{ 'summaryReport.parents' | translate}}: {{ parentNames }}</span>
            </div>
            <div class="downloadAndPhoto flex-col">
                <div class="downlaod flex-cent" >
                    <button class="downloadBtn flex-cent" (click)="downloadPortfolioPDF()">
                        @if(downloadInProcess){
                            <div class="loader loader--style3" title="2">
                                <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                   width="40px" height="30px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                                <path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                                  <animateTransform attributeType="xml"
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 25 25"
                                    to="360 25 25"
                                    dur="0.6s"
                                    repeatCount="indefinite"/>
                                  </path>
                                </svg>
                              </div>                        
                        } @else{
                            <p class="downloadText">Download</p>
                            <img class="download_icon" src="../../../../assets/img/download_icon.png" alt="">
                        }
                    </button>
                </div>
                <div class="imageDiv flex-cent">
                    <img class="profile" src="../../../../assets/img/profile.png" alt="Right Side Image" class="right-image" />
                </div>
            </div>
        </section>
        @for (obj of catData; track obj.category_id){
        <section class="cat-cards" id="cat-cards">
            <div class="cat-name">
                <span>{{ obj.cat_name | translate}}</span>
                <div class="catImageDiv">
                    <img class="catImage" [src]="obj.cat_img" alt="">
                </div>
            </div>
            <div class="responsiveWrap">
                <div class="donutChartDiv">
                    <div class="donutChart" [id]="obj.category_id"></div>
                    <span class="overAlll">{{ obj.result }}% {{ 'portfolio.overAlll' | translate}}</span>
                </div>
                @for(subcatObj of obj.subcats; track subcatObj.subcategory_id){
                <div class="subcat-card flex-cent flex-col">
                    <!-- Subcat Name -->
                    <span class="subcat-heading">{{subcatObj.hasOwnProperty('subcat_name_hi') ? subcatObj.subcat_name_hi
                        : subcatObj.subcat_name }}</span>
                    <!-- Progress bar  -->
                    <div class="progressBarDiv">
                        <div class="progressBar" [style.width.%]="subcatObj['result']"
                            [ngClass]="{'Yellow': subcatObj['result']>=26 && subcatObj['result']<=65 , 'Red': subcatObj['result'] <= 25 , 'Green': subcatObj['result'] >= 66}">
                        </div>
                        @if (subcatObj['result'] >= 90) {
                        <img class="star" src="../../../../../assets/img/star.png" alt="">
                        }
                        <span class="score">{{subcatObj.result | hindiNumber}}%</span>
                    </div>

                    <div class="milestones">
                        <ul class="milestone-list">
                            @for(milestone of subcatObj.milestones; track milestone) {
                            <li class="milestoneElement">
                                <span class="milestone-text">{{ milestone.milestone }}</span>
                                <!-- Conditionally display the icon based on the status -->
                                <img [src]="getIconForStatus(milestone.status)" alt="Status icon"
                                    class="milestone-icon" />
                            </li>
                            }
                        </ul>

                    </div>
                    <!-- remark section -->
                    @if(subcatObj.remarks){
                    <div class="remark ">
                        <span id="remark" class="teacher-remark">{{subcatObj.remarks}}</span>
                    </div>
                    }@else{
                    <!-- if the remark is not given to the child -->
                    <div class="remark">
                        <span id="remark1" class="teacher-remark">{{getReMark(subcatObj.result,
                            subcatObj.subcat_name)}}</span>
                    </div>
                    }

                    @if(subcatObj.images){
                    <div class="imageContainer flex-cent flex-col">
                        <span class="movement">{{ 'portfolio.movement' | translate }}</span>
                        <div class="outerDivImg d-flex align-center just-start">
                            @for(img of getParse(subcatObj.images);let idx = $index; track idx){
                            <div class="ChildimageDiv d-flex align-center just-cent">
                                <img class="childPhoto" [src]="img" alt="childPhoto">
                            </div>
                            }
                        </div>

                    </div>
                    }@else {
                    <div class="imageContainer">
                        <!-- <span>{{ 'portfolio.notUploaded' | translate}}</span> -->
                    </div>
                    }


                </div>
                }
            </div>
        </section>
        }

    </div>
</section>

}@else {
<app-spinner></app-spinner>
}