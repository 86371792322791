import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from '../../services/alert/toast.service';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [RouterLink, CommonModule , TranslateModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit, OnDestroy {
  private toastService = inject(ToastService);
  private translate = inject(TranslateService)
  message: string | null = null;
  countdown: number = 2;
  type: string = "success";
  private toastSubscription!: Subscription;
  private countdownInterval: any;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit() {
    this.toastSubscription = this.toastService.toast$.subscribe(alert => {
      this.message = alert.message;
      this.type = alert.type;
      this.countdown = 2;
      this.startCountdown(); 
    });
  }

  ngOnDestroy() {
    if (this.toastSubscription) {
      this.toastSubscription.unsubscribe();
    }
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval); 
    }
  }

  startCountdown(): void {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval); 
    }
    this.countdownInterval = setInterval(() => { 
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(this.countdownInterval);
        this.message = null;
      }
    }, 1000);
  }
}
