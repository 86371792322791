
<app-navbar [teacherName]="teacherName" [noBack]="false" [navbarDisplay]="navbarDisplay"></app-navbar>
<div class="parent-container" >

  <div class="container flex-cent flex-col">

    <div class="header-card">
      <h2>{{ 'dashboard.title' | translate}}</h2>
    </div>

    <!-- classroom option-->
    <div class="containerOptions d-flex just-between align-center"  (click)="goToClassroom()" role="presentation">
      <span>{{standard}} - {{division}}</span>
      <img class="dashboard-icons" src="../../../../assets/img/yourClassroom.png" alt="">
    </div>

    <!-- other classrooms option -->
    <div class="containerOptions d-flex just-between align-center" >
      <!-- <label>Other Classsrooms</label> -->
      <select id="select" class="other-classroom form-select form-select-lg" >
        <option selected disabled=""> {{'dashboard.otherClassroom' | translate}}</option>
        @for (otherClass of otherClassrooms; track otherClass; let i = $index) {
          <option [value]="otherClass.class_id">
            {{otherClassrooms[i].standard}}-{{otherClassrooms[i].division}}
          </option>
        }
      </select>
      <!-- <img class="dashboard-icons" src="../../../../assets/img/otherClassrooms.svg" alt=""> -->
    </div>

    <!-- connect with parents option -->
    <div class="containerOptions d-flex just-between align-center" (click)="goToConnectWithParents()" role="presentation">
      <span>{{ 'dashboard.connectWithParent' | translate }}</span>
      <img class="dashboard-icons" src="../../../../assets/img/parents.png" loading="lazy" alt="">
    </div>

    <!-- overall class results on dashboard -->
    <div class="results-container flex-cent flex-col" >

      <span class="results-head ">{{'dashboard.classroomResult' | translate}}</span>
      <span class="date-range">{{createdClassRoom}} - {{endSemDate}}</span>
      <div class="chart-content d-flex just-around align-center">
        <!-- view gray chart if no assessments taken  -->
        @if ( NoResults === true) {
          <div class="noResults">
            <img calss="noResultsImg" src="../../../../assets/img/incomplete_category.png" alt="NOT Started">
          </div>
        }
        <!-- view peichart as per the average of the assessments completed of whole class -->
        @if (NoResults === false) {
          <div class="overallResultPie">
            <div class="peichart" id="chartdiv"></div>
          </div>
        }

        <div class="average flex-cent flex-col">
          <p>{{'dashboard.currnetAverage' | translate}}</p>
          <h3 class="avg">{{classResult | hindiNumber}}%</h3>
        </div>

      </div>

    </div>
  </div>
  <app-footer-navbar [class_id]="class_id"></app-footer-navbar>
</div>