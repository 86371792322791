// global-object.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { setStudentObject,resetStudentObject } from './student.actions';
import { StudentState } from '../../app/shared/interfaces/store';

const initialState : StudentState = {
    studentObject: {
        studentName: '',
        student_id: 0,
        dob_date: 0,
        dob_month: 0,
        dob_year: 0,
        age: ''
    }
} 

export const StudentObjectReducer  = createReducer(
  initialState,
  on(setStudentObject, (state, { studentObject } ) : StudentState => ({ ...state , studentObject })),
  on(resetStudentObject , () : StudentState => initialState)
);



