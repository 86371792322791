import { Component, inject } from '@angular/core';
import { NavbarComponent } from '../../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../../shared/component/footer-navbar/footer-navbar.component';
import { NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../../../shared/pipe/hindi-number.pipe';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

interface CollatedResult {
  catId: number;
  subcatName: string;
  subcatId: number;
  teacherResult: number;
  parentResult: number;
  average: number;
}

interface ParentResult {
  cat_name: string;
  subcat_id: number;
  score: number;
}

interface TeacherResult {
  subcatId?: number;
  score: number;
  premiumMappedSubcatId?: number;
}

interface PremiumMappedIds {
  basicSubcatId: number;
  premiumMappedSubcatId: number;
  subcat_name: string;
}

@Component({
  selector: 'app-subcat-collated',
  standalone: true,
  imports: [
    NgClass,
    NavbarComponent,
    FooterNavbarComponent,
    TranslateModule,
    HindiNumberPipe,
  ],
  templateUrl: './subcat-collated.component.html',
  styleUrl: './subcat-collated.component.css',
})
export class SubcatCollatedComponent {
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  private activatedRoute = inject(ActivatedRoute);
  private translate = inject(TranslateModule);

  navbarDisplay: string = 'none';
  class_id: number = 0;
  catName: string = '';
  catId: number = 0;
  averageResult: number = 0;
  pieColor: number = 0xb9b9b9;
  parentAvg: number = 0;
  teacherAvg: number = 0;
  collatedAvg: number = 0;
  params: any;
  parentsResults: ParentResult[] = []; // here cat_name is actually subcat_name.
  teacherResults: TeacherResult[] = [];
  premiumMappedIds: PremiumMappedIds[] = [];
  collatedResults: CollatedResult[] = []; // interface array of objects.
  mode: string = 'collated'; // neccessary for opening summary-report component in collated mode when navigated back from this component.
  classId: number = 0;
  studentId: number = 0;
  goBackTo: string = 'collated';

  constructor() {
    // console.log("constructor called !!!");
    this.classId = Number(this.route.snapshot.paramMap.get('class_id'));
    // console.log('class id recieved in collated : ', this.classId);
    this.params = this.route.snapshot.queryParams;
    // console.log('params recieved : ', this.params);

    this.catName = this.params.catName;
    // console.log('catName is: ', this.catName);

    this.catId = Number(this.params.catId);
    this.parentsResults = JSON.parse(this.params.parentsResults);
    this.teacherResults = JSON.parse(this.params.teacherResults);
    this.premiumMappedIds = JSON.parse(this.params.premiumMappedSubcat_id)!;

    // console.log('parsed parents results are : ', this.parentsResults);
    // console.log('parsed teacher results are : ', this.teacherResults);

    this.averageResult = Number(this.params.average);
  }

  ngOnInit() {

    if (this.catName === 'Basic') {
     

      let i = 0;
      for (let parentObj of this.parentsResults) {
        let j = 0;
        for (let teacherObj of this.teacherResults) {
          if (
            parentObj.subcat_id === this.premiumMappedIds[j].basicSubcatId &&
            teacherObj.premiumMappedSubcatId ===
              this.premiumMappedIds[j].premiumMappedSubcatId &&
            teacherObj.score !== 0 &&
            parentObj.score !== 0
          ) {
            // both, parents and teacher's subcat's scores available.
            this.collatedResults.push({
              catId: this.catId,
              subcatName: parentObj.cat_name,
              subcatId: parentObj.subcat_id,
              teacherResult: teacherObj.score,
              parentResult: parentObj.score,
              average: Math.round((teacherObj.score + parentObj.score) / 2),
            });
          } else if (
            parentObj.subcat_id === this.premiumMappedIds[j].basicSubcatId &&
            teacherObj.premiumMappedSubcatId ===
              this.premiumMappedIds[j].premiumMappedSubcatId &&
            teacherObj.score === 0 &&
            parentObj.score !== 0
          ) {
            // teacher's subcat score NOT available.
            this.collatedResults.push({
              catId: this.catId,
              subcatName: parentObj.cat_name,
              subcatId: parentObj.subcat_id,
              teacherResult: 0,
              parentResult: parentObj.score,
              average: parentObj.score,
            });
          } else if (
            parentObj.subcat_id === this.premiumMappedIds[j].basicSubcatId &&
            teacherObj.premiumMappedSubcatId ===
              this.premiumMappedIds[j].premiumMappedSubcatId &&
            teacherObj.score !== 0 &&
            parentObj.score === 0
          ) {
            // parent's subcat score NOT available.
            this.collatedResults.push({
              catId: this.catId,
              subcatName: parentObj.cat_name,
              subcatId: parentObj.subcat_id,
              teacherResult: teacherObj.score,
              parentResult: 0,
              average: teacherObj.score,
            });
          }
          j++;
        }
        i++;
      }
    } else {
      // premium
      // assigning values to the collatedResults interface according to the respective scores of the subcategories.
      // this.parentsResults.forEach((parentObj)=>{
      for (let parentObj of this.parentsResults) {
        for (let teacherObj of this.teacherResults) {
          if (
            parentObj.subcat_id === teacherObj.subcatId &&
            parentObj.score !== 0 &&
            teacherObj.score !== 0
          ) {
            // both parent's and teacher's scores are available
            this.collatedResults.push({
              catId: this.catId,
              subcatName: parentObj.cat_name,
              subcatId: parentObj.subcat_id,
              teacherResult: teacherObj.score,
              parentResult: parentObj.score,
              average: Math.round((teacherObj.score + parentObj.score) / 2),
            });
          } else if (
            parentObj.subcat_id === teacherObj.subcatId &&
            parentObj.score !== 0 &&
            teacherObj.score === 0
          ) {
            // parent's score available , but teacher's score unavailable.
            this.collatedResults.push({
              catId: this.catId,
              subcatName: parentObj.cat_name,
              subcatId: parentObj.subcat_id,
              teacherResult: 0, // assigning 0 by default
              parentResult: parentObj.score,
              average: parentObj.score, // in this case average will be same as parent's score since teacher's score is unavailable.
            });
          } else if (
            parentObj.subcat_id === teacherObj.subcatId &&
            parentObj.score === 0 &&
            teacherObj.score !== 0
          ) {
            // teacher's score available , but teacher's score unavailable.
            this.collatedResults.push({
              catId: this.catId,
              subcatName: parentObj.cat_name,
              subcatId: parentObj.subcat_id,
              teacherResult: teacherObj.score, // assigning 0 by default
              parentResult: 0,
              average: teacherObj.score, // in this case average will be same as parent's score since teacher's score is unavailable.
            });
          }
        }
      }
    }

    // console.log('collatedResults array : ', this.collatedResults);

    // setting piechart Color
    if (this.catName === 'Basic') {
      this.pieColor = 0x4fd6ff; // blue color for basic
    } else {
      if (this.averageResult <= 25) {
        this.pieColor = 0xff8b01; // orange color
      } else if (this.averageResult && this.averageResult <= 65) {
        this.pieColor = 0xffee52; // yellow color
      } else if (this.averageResult >= 66) {
        this.pieColor = 0xabff58; // green color
      }
    }
    this.createPieChart('chartDiv', this.averageResult, this.pieColor);
  }

  createPieChart(chartId: string, result: number, color: number) {
    const root = am5.Root.new(chartId);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        // layout: root.verticalLayout,
      })
    );

    root.setThemes([am5themes_Animated.new(root)]);

    // pieChart data
    const data = [
      {
        status: 'Done',
        value: result,
        sliceSettings: {
          fill: am5.color(color), // score color
          stroke: am5.color(0x000000),
        },
      },
      {
        country: 'NotDone',
        value: 100 - result,
        sliceSettings: {
          fill: am5.color(0xffffff), // white
          stroke: am5.color(0x000000),
        },
      },
    ];

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'status',
      })
    );

    // adding colours
    series.slices.template.setAll({
      templateField: 'sliceSettings',
      showTooltipOn: 'click',
    });

    // hiding labels
    series.labels.template.setAll({
      maxWidth: 1,
      oversizedBehavior: 'hide',
    });
    // series.labels.template.setAll("hide");

    series.ticks.template.set('visible', false);

    // annimations
    series.animate({
      key: 'startAngle',
      to: result,
      duration: 3000,
      easing: am5.ease.yoyo(am5.ease.linear),
    });

    series.data.setAll(data);
    series.appear();
    chart.appear();
  }
}
