import { ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { TeacherObjectReducer } from './teacher/teacher.reducer';
import { StudentObjectReducer } from './student/student.reducer';
import { AppState } from '../app/shared/interfaces/store';
/**
 *  Combine the reducers into the root reducer
 *  Need to add the reducers in below object
 * 
 *  */
export const appReducers: ActionReducerMap<AppState> = {
  // This reducer keeps track of the router state
  router: routerReducer, 
  teacherObject: TeacherObjectReducer, 
  studentObject : StudentObjectReducer
};
