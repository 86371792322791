import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../../../config/config';

@Injectable({
  providedIn: 'root'
})
export class classRoomService {
  private http = inject(HttpClient);
  private config = inject(ApplicationConfig);

  url :string = 'http://localhost:8080';
  token :string | null = "";

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
      const config = this.config;
 
      this.url =  config.getAPIEnvironment();
      if(typeof window !== 'undefined' && window.localStorage){
        this.token = localStorage.getItem('token');
      }
  }

  getClassRoomByTeacherID(teacherId:number): Observable<object> {

     const reqHeaders : HttpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    });

    const data = {
      teacher_id: teacherId,
      // school_id : school_id,
    };
     
    return this.http.post<any>(`${this.url}/class/getClassByTeacherId`, data, { headers: reqHeaders });
  }

  getClassById(
    classID:number,
  ){
    const reqHeaders : HttpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${this.url}/class/getClassById/${classID}`, { headers: reqHeaders });
  }

}



