<app-navbar [navbarDisplay]="navbarDisplay" [class_id]="classId" [mode]="mode" [goBackTo]="goBackTo"></app-navbar>

<div class="parent-container flex-cent flex-col">
  <div class="header-container"
    [ngClass]="{'lightGreen' : averageResult >= 66 , 'lightRed' : averageResult <= 25 , 'lightYellow' : averageResult >= 26 && averageResult <= 65 , 'basicProfile' : catName === 'Basic'}">
    <div class="heading">
      <h3 class="textBold">{{catName | translate}}</h3>
    </div>
    <div class="piechart">
      <div id="chartDiv"></div>
      <div class="score">
        <!-- <h6>Avg.</h6> -->
        <h4 class="averageColor">{{averageResult | hindiNumber}}%</h4>
      </div>
    </div>
  </div>

  <div class="subcat-container flex-cent flex-col"
    [ngClass]="{'lightGreen' : averageResult >= 66 , 'lightRed' : averageResult <= 25 , 'lightYellow' : averageResult >= 26 && averageResult <= 65 , 'basicProfile' : catName === 'Basic'}">
    <div class="heading">
      <h5 class="textBold">{{'subcatCollated.developmentAreas' | translate}}</h5>
    </div>
    @for(obj of collatedResults; track obj){
      <div class="subcategory-card flex-cent flex-col">
        <div class="subcatName">
          @if(obj.subcatName === 'Speaking'){
            <h5>{{'subcatCollated.speaking' | translate}}</h5>
          }@else{
            <h5>{{obj.subcatName}}</h5>
          }
         

        </div>
        <div class="subCat-data flex-col">
          <!-- parent-result -->
          <div class="result flex-cent">
            <img src="../../../../../assets/img/collated_parents.png" alt="">
            <div class="progress-section flex-cent flex-col">
                <h6 class="label">{{'subcatCollated.parent' | translate}}</h6>
              <div class="progress-bar flex-cent flex-row just-between">
                <div class="progress" [style.width.%]="obj.parentResult" [ngClass]="{'Yellow': obj.parentResult >= 26 && obj.parentResult <= 65 , 'Red': obj.parentResult <= 25 , 'Green': obj.parentResult >= 66}">
                  
                </div>
                @if(obj.parentResult === 0){
                  <div class="average">
                    <h6>{{'subcatCollated.pending' | translate}}</h6>
                  </div>
                } @else{
                  <div class="average">
                    <h6>{{obj.parentResult | hindiNumber}}%</h6>
                  </div>
                }
              </div>
            </div>
          </div>
  
          <!-- teacher-result -->
          
            <div class="result flex-cent">
              <img src="../../../../../assets/img/teacher.png" alt="">
              <div class="progress-section flex-cent flex-col">
                <h6 class="label">Teacher</h6>
                <div class="progress-bar flex-cent flex-row just-between">
                  <div class="progress" [style.width.%]="obj.teacherResult" [ngClass]="{'Yellow': obj.teacherResult >= 26 && obj.teacherResult <= 65 , 'Red': obj.teacherResult <= 25 , 'Green': obj.teacherResult >= 66}">
                    
                  </div>
                  @if(obj.teacherResult === 0){
                  <div class="average">
                    <h6>{{'subcatCollated.pending' | translate}}</h6>
                  </div>
                } @else{
                  <div class="average">
                    <h6>{{obj.teacherResult | hindiNumber}}%</h6>
                  </div>
                }
                </div>
              </div>
            </div>
          
  
          <!-- average-result -->
          <div class="result flex-cent">
            <img src="../../../../../assets/img/average.png" alt="">
            <div class="progress-section flex-cent flex-col">
              <h6 class="label">Average</h6>
              <div class="progress-bar flex-cent flex-row just-between">
                <div class="progress colAvgColor" [style.width.%]="obj.average" >
                  
                </div>
                <div class="average">
                  <h6>{{obj.average | hindiNumber}}%</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

  </div>

</div>

<app-footer-navbar [class_id]="class_id"></app-footer-navbar>