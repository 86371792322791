@if (loader) {
<app-navbar [navbarDisplay]="navbarDisplay"></app-navbar>

<div class="parent-container dFlex">
  <div id="camera-area" class="camera-area dFlex" (click)="disableActions()" role="presentation">
    <video #video id="video" autoplay></video>
    <div class="photos-container">
      <div id="photo-preview" class="photo-preview">
        <h6 class="photoCounter">{{photoCounter}}</h6>

        <canvas #canvas id="canvas"></canvas>
        <ul class="photos ">
          @for (pic of photoPreview; track pic; let i = $index) {
          <li>
            <img class="photo" [src]="pic" (click)="actions(pic, i)" alt="" role="presentation" />
            <!-- photo download and delete options -->
            @if (photoActions[i]) {
            <div class="actions dFlex">
              <img class="photoActions-button" (click)="downloadImage(pic)"
                src="../../../../assets/img/download_icon.png" alt="" role="presentation">
              <img class="photoActions-button" (click)="deletePhoto( i )" src="../../../../assets/img/delete_icon.svg"
                alt="" role="presentation">
            </div>
            }
          </li>
          }
        </ul>
      </div>


    </div>
    <div class="button-div">
      @if (photoClicked) {
        <div class="button-section">
          <div class="buttons">
            <button class="click-Btn" (click)="cancel()">Cancel</button>
            <button class="click-Btn" (click)="saveImages()" [disabled]="isClicked">Save</button>
          </div>
        </div>
        }
        <div class="click-buttons dFlex">
          <button id="snap" class="click-photo-btn" (click)="capture()">
            <div class="clickIcon"></div>
          </button>
          <button class="switchCamera" (click)="switchCamera()"><img src="../../../../assets/img/switch_camera_icon.png"
              alt=""></button>
        </div>
    
        <p class="note">Note: Photos once saved cannot be updated later.</p>
    </div>
   
  </div>


</div>
} @else {
<app-spinner></app-spinner>
}