import { Injectable, NgZone, inject } from '@angular/core';
declare const webkitSpeechRecognition: new () => any;

@Injectable({
  providedIn: 'root'
})
export class SpeechRecognitionService {
  private zone = inject(NgZone);

  recognition: any;
  isListening = false;
  resultText = '';
  recognitionTimeout : any;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    if (typeof window !== 'undefined' && window.localStorage) {
       this.init()
    }
  }  

  init() {
    try {
      if ('webkitSpeechRecognition' in window) {
        this.recognition = new webkitSpeechRecognition();
        this.recognition.continuous = false;
        this.recognition.interimresults = false;
        this.recognition.lang = 'en-US';

        this.recognition.onresult = (e : any) => {
          this.resultText = e.results[0][0].transcript;
        };

      } else {
             console.log("error");
      }
    } catch (error) {
      console.log("error",error)   
    }
  
  }

  // startAutoStopTimer() {
  //   this.recognitionTimeout = setTimeout(() => {
  //     console.log('Stopping recognition after 20 seconds of inactivity');
  //     this.stopListening();
  //   }, 20000); // 20 seconds
  // }


  startListening() {
    this.isListening = true;
    this.recognition.start();
  }

  stopListening() {
    console.log("resultText",this.resultText)
    this.isListening = false;
    this.resultText = '';
    this.recognition.stop();
  }

  getTranscript() {
    return this.resultText;
  }
}
