<div class="sectionBlock">
  <!-- <img src="../../../../assets/img/loginBg.jpg" class="bgImage md-none" alt="backgroundImage"> -->
  <div class="container d-flex  align-items-center justify-content-center flex-column">

    <div class="BMC_logo position-absolute top-0 start-50 translate-middle">
      <img src="../../../../assets/img/BMC_R_Logo_v2_3.png" alt="logo">
    </div>

    <div class="saapLogo ">
      <img src="../../../../assets/img/samLogo.png" alt="saapLogo">
    </div>

    <form [formGroup]="loginForm" class="loginForm">
      <div class="card w-100 h-100 flex-cent">
        <select [value]="selectedLanguage" (change)="onLanguageChange($event)">
          <option value="en">English</option>
          <option value="hi">Hindi</option>
        </select>
        
        <div class="w-100 mb-md-5 pb-5 text-center flex-cent flex-column">
          <h2 class="teacherLogin mb-2 text-uppercase mt-2">{{ 'login.title' | translate}}</h2>
          <div class=" mt-3 w-100">
            <input type="email" id="userEmail" [placeholder]="'login.email' | translate" class=" form-control form-control-lg"
              [ngClass]="isEmailValid" formControlName="email" (input)="validateEmail()" required />
            <!-- creating a div for the validating messages to display -->
            @if (loginForm.controls['email'].invalid &&(loginForm.controls['email'].touched || loginForm.controls['email'].dirty || isFormSubmitted)) {
              <div class="invalid-feedback"
                >
                @if (loginForm.controls['email'].errors?.['required']) {
                  <span>
                    {{'login.requiredField' | translate}}
                  </span>
                }
                @if (loginForm.controls['email'].errors?.['email']) {
                  <span>
                    {{'login.invalidEmail' | translate}}
                  </span>
                }
              </div>
            }
          </div>

          <div class="mt-3 w-100 mb-2">
            <input type="password" id="userPassword" [placeholder]="'login.password' | translate" class=" form-control form-control-lg"
              [ngClass]="isPasswordValid" formControlName="password" (input)="validatePassword()" required />

            <div class="show-password">
              <input  type="checkbox" (click)="togglePassword()">
              <p>{{ 'login.showPassword' | translate }}</p>
            </div>

            <!-- creating a div for the validating messages to display -->
            @if (loginForm.controls['password'].invalid &&(loginForm.controls['password'].touched || loginForm.controls['password'].dirty || isFormSubmitted)) {
              <div class="invalid-feedback"
                >
                @if (loginForm.controls['password'].errors?.['required']) {
                  <span>
                    {{'login.passwordRequired' | translate}}
                  </span>
                }
                @if (loginForm.controls['password'].errors?.['minlength']) {
                  <span>
                    {{'login.minimumPassword' | translate}}
                  </span>
                }
              </div>
            }
          </div>

          <button class="loginBtn btn btn-primary mt-3 "
                  type="submit"
                  (click)="validateForm()">
                {{'login.login' | translate}}
        </button>
          <!-- <p class=" mt-3 mb-5 pb-lg-2">
          <span class=""  disabled>Forgot password?</span>
        </p> -->
      </div>
    </div>
  </form>
</div>
</div>