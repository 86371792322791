import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PwaInstallService {
  private deferredPrompt: any;
  public installPromptEvent = new BehaviorSubject<boolean>(false);
  public upgradeAvailable = new BehaviorSubject<boolean>(false);

  constructor() {
    // Listen for the `beforeinstallprompt` event
    if (typeof window !== 'undefined' && window.localStorage) {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
        this.deferredPrompt = event;
        this.installPromptEvent.next(true);
      });
    }

    // Check for updates in the service worker
    this.checkForUpdates();
  }

  // Trigger the install prompt
  public showInstallPrompt(): void {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        this.deferredPrompt = null;
        this.installPromptEvent.next(false);
      });
    }
  }

  public cancelInstallPrompt(): void {
    this.deferredPrompt = null; 
    this.installPromptEvent.next(false); 
  }

  private checkForUpdates(): void {
    // Register a service worker
    if (typeof window !== 'undefined' && window.localStorage) {

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/ngsw-worker.js').then(registration => {
        // Listen for updates to the service worker
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;

          // Null check for installingWorker
          if (installingWorker) {
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // A new version is available
                  this.upgradeAvailable.next(true);
                  this.promptUserToUpgrade();
                }
              }
            };
          } else {
            console.warn('No installing worker found');
          }
        };
      }).catch(error => {
        console.error('Error during service worker registration:', error);
      });
    }
  }
  }

  private promptUserToUpgrade(): void {
    // Notify the user about the upgrade (you can customize this)
    const userConfirmed = confirm('A new version of the app is available. Would you like to reload?');
    if (userConfirmed) {
      window.location.reload();
    }
  }
}
