
<div class="spinner-container d-flex align-items-center justify-content-center">
    <div class="spinner-logo">
        <img src="../../../../assets/img/loader.png" alt="App Logo" />
        <div class="half-circle"></div>
    </div>
</div>




