import { Injectable, inject } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { ApplicationConfig } from '../../../config/config';
import { ToastService } from '../alert/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class ResultsService {
  private http = inject(HttpClient);
  private toastService = inject(ToastService);
  private config = inject(ApplicationConfig);
  private translate = inject(TranslateService);
  private router = inject(Router);

  url: string = 'http://localhost:8080';
  token: string | null = '';
  reuestHeader: HttpHeaders = new HttpHeaders();

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    const config = this.config;

    this.url = config.getAPIEnvironment();
  }

  setReqHeader() {
    let reqHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
      if (this.token) {
        reqHeaders = reqHeaders.set('Authorization', 'Bearer ' + this.token);
      }
    }

    return reqHeaders;
  }

  getSubcatResult(
    student_id: number,
    class_id: number,
    cat_id: number,
    dob_month: number,
    dob_date: number,
    dob_year: number,
    targetedLang: string
  ): Observable<object> {
    this.reuestHeader = this.setReqHeader();

    const data = {
      student_id: student_id,
      class_id: class_id,
      cat_id: cat_id,
      targetedLang: targetedLang,
      dob_month: dob_month,
      dob_day: dob_date,
      dob_year: dob_year,
    };

    return this.http.post<any>(`${this.url}/result/get_subcat_result`, data, {
      headers: this.reuestHeader,
    });
  }

  // API for getting completed milstones and thier results

  getCompletedMilestones(
    class_id: number,
    student_id: number,
    dob_month: number,
    dob_date: number,
    dob_year: number,
    screen: string
  ): Observable<object> {
    this.reuestHeader = this.setReqHeader();

    return this.http
      .get<any>(
        `${this.url}/result/get_completed_milestones/${class_id}/${student_id}/${dob_month}/${dob_date}/${dob_year}`,
        { headers: this.reuestHeader }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404 && screen === 'result') {
            if (typeof window !== 'undefined') {
              this.toastService.showAlert(
                this.translate.instant('result.emptyRemark'),
                'Note'
              );
              window.history.back();
            }
            return of([]);
          } else if (error.status === 404 && screen === 'summary-report') {
            this.toastService.showAlert(
              this.translate.instant('result.emptyRemark'),
              'Note'
            );
            this.router.navigate(['/connect-with-parents', class_id]);
            return of([]);
          } else {
            return of([]);
          }
        })
      );
  }

  // for piechart on the dashboard
  getClassOverallResult(class_id: number): Observable<object> {
    this.reuestHeader = this.setReqHeader();

    return this.http.get<any>(
      `${this.url}/result/get_class_overall_result/${class_id}}`,
      { headers: this.reuestHeader }
    );
  }

  getPortFolio(
    classId: number,
    childID: number,
    dob_month: number,
    dob_day: number,
    dob_year: number,
    targetedLang: string
  ): Observable<object> {
    this.reuestHeader = this.setReqHeader();

    return this.http.get<any>(
      `${this.url}/result/portfolio/${classId}/${childID}/${dob_month}/${dob_day}/${dob_year}/${targetedLang}`,
      { headers: this.reuestHeader }
    );
  }
}
