@if(showImage){
<div class="parent-container flex-cent flex-col" >
  <div class="display-section d-flex just-start align-center flex-col">

    <div #image class="photos d-flex just-center align-center flex-col"  >

      <div class="photo flex-cent" (touchstart)="onTouchStart($event)"
      (touchend)="onTouchEnd($event)">
        <img [src]="recievedAllImages[currentImageIndex]" alt="ImageView">
      </div>
      <div class="buttons d-flex just-between align-center ">
        <button (click)="previousImage()">
          <img src="../../../../assets/img/previous_button.png" alt="">
        </button>
        <button (click)="downloadImage(recievedAllImages[currentImageIndex])">
          <img src="../../../../assets/img/download.png" alt="">
        </button>
        <div class="backbutton flex-cent" (click)="closeImageView()">
          <p>X</p>
        </div>
        <button (click)="nextImage()"><img src="../../../../assets/img/next_button.png" alt=""></button>
      </div>
    </div>

  </div>

</div>
}