import { Routes } from '@angular/router';
import { LoginpageComponent } from './main/authenticate/loginpage/loginpage.component';
import { ClassRoomComponent } from './main/core/class-room/class-room.component';
import { SubcatComponent } from './main/core/subcat/subcat.component';
import { MilestoneComponent } from './main/core/milestone/milestone.component'
import { SpinnerComponent } from './shared/component/spinner/spinner.component';
import { PageNotFoundComponent } from './shared/component/page-not-found/page-not-found.component';
import { AuthGuard } from './shared/services/utility/auth-guard.service';
import { ResultComponent } from './main/results/result/result.component';
import { NavbarComponent } from './shared/component/navbar/navbar.component';
import { SubcatResultsComponent } from './main/results/subcat-results/subcat-results.component';
import { DashboardComponent } from './main/core/dashboard/dashboard.component';
import { PortfolioComponent } from './main/core/portfolio/portfolio.component';
import { TeacherprofileComponent } from './main/core/teacherprofile/teacherprofile.component';
import { ConnectWithParentsComponent } from './main/connectWithParents/connect-with-parents/connect-with-parents.component';
import { StatsResultsComponent } from './main/core/stats-results/stats-results.component';
import { InviteConfirmComponent } from './main/connectWithParents/invite-confirm/invite-confirm.component';
import { ParentShareComponent } from './main/connectWithParents/parent-share/parent-share.component';
import { SummaryReportComponent } from './main/results/summary-report/summary-report/summary-report.component';
import { SubcatSummaryComponent } from './main/results/summary-report/subcat-summary/subcat-summary.component';
import { CameraComponent } from './main/core/camera/camera.component';
import { SubcatCollatedComponent } from './main/results/summary-report/subcat-collated/subcat-collated.component';

export const routes: Routes = [
     {path: '', redirectTo: '/dashboard',pathMatch: 'full'},
     {path: 'login', component: LoginpageComponent},
     {path: 'class-room/:class_id/:teacher_name', component: ClassRoomComponent},
     {path: 'sub-categories/:class_id/:student_id/:categoryId/:categoryName', component: SubcatComponent},
     {path: 'milestone/:class_id/:student_id/:categoryId/:cat_name/:sub_id/:subcat_name/:type',component: MilestoneComponent},
     {path: 'spinner', component:SpinnerComponent},
     {path: 'result/:class_id/:student_id', component:ResultComponent},
     {path: 'navbar', component:NavbarComponent},
     {path: 'subcat-results/:class_id/:student_id/:cat_id', component:SubcatResultsComponent},
     {path: 'dashboard', component:DashboardComponent , canActivate: [AuthGuard]},
     {path: 'portfolio/:class_id/:student_id', component:PortfolioComponent},
     {path: 'teacherprofile',component: TeacherprofileComponent},
     {path: 'connect-with-parents/:class_id',component:ConnectWithParentsComponent},
     {path: 'stats-results', component: StatsResultsComponent},
     {path: 'invite-confirm',component: InviteConfirmComponent},
     {path: 'parent-share/:class_id', component: ParentShareComponent},
     {path: 'summary-report/:class_id/:mode',component: SummaryReportComponent},
     {path: 'subcat-summary/:class_id', component:SubcatSummaryComponent},
     {path: 'camera', component:CameraComponent},
     {path: 'subcat-collated/:class_id', component: SubcatCollatedComponent},
     {path: '**', component:PageNotFoundComponent }
];
