// global-object.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { setTeacherObject , resetTeacherObject } from './teacher.actions';
import { TeacherState } from '../../app/shared/interfaces/store';


const initialState : TeacherState  ={
    teacherObject : {
        teacher_id: 1,
        school_id: 1,
        teacher_name: "",
        teacher_gender: 1,
        teacher_mobile: 1234567890,
        school_Name: "",
        school_logo: "",
    }
}; 

export const TeacherObjectReducer  = createReducer(
  initialState,
  on(setTeacherObject, (state, { teacherObject } ) : TeacherState => ({ ...state , teacherObject })),
  on(resetTeacherObject , () : TeacherState => initialState)
);



