@if (message) {
  <div class="overlay"></div>
}

@if (message) {
  <div class="notifications">
    <h4 class="heading">{{header}}</h4>
    <p class="message">{{message}}</p>
    @if(notice){
      <div class="buttons">
        <button class="backButton" (click)="clearAlert()"> {{'ok' | translate}} </button>

      </div>
    }@else{
      <div class="buttons">
        <!-- <button NgIf="okButton">ok</button> -->
        <button class="backButton" (click)="clearAlert()"> {{'cancel' | translate}} </button>
        <button class="backButton" (click)="confirm()"> {{'confirm' | translate}} </button>
      </div>
    }
  </div>
}
