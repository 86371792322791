<nav class="navBar position-fixed bottom-0 start-0 w-100">
    <!-- Left align icon -->
    <ul class="nav justify-content-around align-items-center w-100 h-100">
      <!-- First navigation item -->
      <!-- Second navigation item -->

      <!-- role attribute is used because in typscript
           the click event is accompanied with at least one key event keyup,
           keydown or keypress.-->
      <li class="" (click)="goToDashboard()" role="presentation">
        <img src="../../../../assets/img/Vectorhome.svg"  class="navIcon" alt="" srcset="">
      </li>

      <li class="" (click)="goToClassroom()" role="presentation">
        <img src="../../../../assets/img/yourClassroom.png"  class="navIcon" alt="" srcset="">
      </li>

      <li class="" (click)="goToConnectWithParents()" role="presentation">
        <img src="../../../../assets/img/parents.png"  class="navIcon" alt="" srcset="">
      </li> 

      <li class="" (click)="open(content)" role="presentation">
        <img src="../../../../assets/img/menu.png"  class="navIcon" alt="" srcset="">
      </li>
    </ul> 
</nav>


<ng-template #content let-offcanvas>
  <div class="slideIn-menu w-100">
    <div class="offcanvas-header w-100">
      <span class="offcanvas-title fs-5 text-black" id="offcanvas-basic-title">{{'MENU' | translate}} </span>
      <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')" ></button>
    </div>
    

	
	  <div class="offcanvas-body d-flex flex-column">
		  <span class="teacherName fs-6 fw-bold">{{ 'hello' | translate}}, <br>{{teacherName}}</span>
      <div class=" mt-3 options d-flex flex-column">
        <span class="fs-6 fw-semibold" (click)="goToTeacherProfile()" role="presentation">{{ 'yourProfile' | translate}}</span>
        <span class="mt-2 fs-6 fw-semibold text-danger" (click)="logout()" role="presentation">{{ 'Log Out' | translate}}</span>      
      </div>
		</div>  
  </div>
  <span class="version mb-2 ms-3 position-absolute bottom-0 start-0">{{AppVersion}}</span>
</ng-template>