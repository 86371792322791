import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CoreUtilityService {
  private translate = inject(TranslateService);
  monthNames = [
    "Jan", "Feb", "March", "April", "May", "June", "July", "August",
    "Sept", "Oct", "Nov", "Dec"
  ];

  constructor() { }

  getFormattedDate(timestamp: string | number | Date) {

    // Convert to Date object
    const date = new Date(timestamp);

    const day = date.getDate().toString().padStart(2, '0'); 
    const month = this.monthNames[date.getMonth()]; 
    const year = date.getFullYear().toString().slice(-2); 

   return `${day} ${month} ${year}`;
  }

  getSixMonthLaterDate(timestamp: string | number | Date){
    const sixMonthsLater = new Date(timestamp);
    sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);

    const dayLater = sixMonthsLater.getDate().toString().padStart(2, '0');
    const monthLater = this.monthNames[sixMonthsLater.getMonth()];
    const yearLater = sixMonthsLater.getFullYear().toString().slice(-2);

   return `${dayLater} ${monthLater} ${yearLater}`;
  }

  getRemarkDefault(score: number, subCatName: string, studentName: string) {
    if (score <= 26) {
      return `${score} ${this.translate.instant('result.lagging')} ${subCatName} ${this.translate.instant('result.development')}`;
    } else if (score >= 27 && score <= 66) {
      return `${studentName} ${this.translate.instant('result.encouraged')} ${subCatName} ${this.translate.instant('result.development')}`;
    } else if (score >= 67) {
      return `${studentName} ${this.translate.instant('result.onTrack')} ${subCatName} ${this.translate.instant('result.development')}`;
    } else {
      return '';
    }
  }

  /**
  * For  calculating the age we needed these 3 parameters 
  * @param birthYear 
  * @param birthMonth 
  * @param birthDate 
  * @returns 
  */

  calculateAge(birthYear: number, birthMonth: number, birthDate: number) {
    // For calculaitng  the age we needed the current date and birth date we are getting that from below two lines
    const currentDate = new Date();
    const birthDateObj = new Date(birthYear, birthMonth - 1, birthDate);

    // Thses  variables are stor ing the difference of birthdate and current date 
    // We will get the age with the help of thses variables 
    const yearsDiff = currentDate.getFullYear() - birthDateObj.getFullYear();
    const monthsDiff = currentDate.getMonth() - birthDateObj.getMonth();
    const daysDiff = currentDate.getDate() - birthDateObj.getDate();

    // for storing the age 
    let age = '';

    /**
     * If the month difference is less than 0 or have same month
     * Then we need to add 12 in month differen as it shows that the current month is the birthmonth of child
     * And birth date isn't come yet 
     */

    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {

      // as the birthday isn't come yet so wee need to subtract it with 1 
      let year = yearsDiff - 1;
      age = `${this.translate.instant(`num.${year}`)}${this.translate.instant('y')} `;
      if (monthsDiff < 0) {
        age += `${this.translate.instant(`num.${12 + monthsDiff}`)}${this.translate.instant('m')}`;
      }
    } else {
      // AS mention above if the birth date is passed in the current year we just need to assign both to age vaiable
      age = `${this.translate.instant(`num.${yearsDiff}`)}${this.translate.instant('y')} `;
      if (monthsDiff > 0) {
        age += ` ${this.translate.instant(`num.${monthsDiff}`)}${this.translate.instant('m')}`;
      }
    }
    return age;
  }
}
