<app-navbar [navbarDisplay] = "navbarDisplay" [class_id]="class_id" [goBackTo]="goBackTo"></app-navbar>

<div class="parent-container flex-cent flex-col">

    <div class="header-card d-flex align-center just-between">
        <div class="profile-photo d-flex align-end just-center flex-col">
            <img src="../../../../assets/img/profile.png" alt="">
            <!-- <div class="addProfilePhoto flex-cent" (click)="openCamera()">
               <p>+</p>
            </div> -->
        </div>
        <div class="details flex-cent flex-col">
            <div class="nameAge d-flex just-around">
                    <h3 class="name">{{child_name}}</h3>
                    <h5 class="age">{{calculateAge(dob_year, dob_month, dob_date)}}</h5>  
            </div>
            <div class="parent-details">
                <h6>{{'parentShare.parents' | translate}} : {{parent_name}}</h6>
                <h6>{{'parentShare.email' | translate}} : {{parent_email}}</h6>
                <h6>{{'parentShare.contact' | translate}} : {{parent_contact}}</h6>
            </div>
        </div>
    </div>

    <div class="message d-flex just-start flex-col">
        <h6 class="message-title">{{'parentShare.sendMsgToParents' | translate}}</h6>
        <div class="typeAndSend d-flex align-center just-between">
            <textarea class="textArea" name="message" id="message" placeholder="{{'parentShare.yourMsgHere' | translate}}"></textarea>
            <button class="sendBtn" (click)="sendPrivateMessage()"><img src="../../../../assets/img/sendBtn.png" alt=""></button>
        </div>
    </div>

    <div class="view-summary flex-cent">
        <button class="summaryBtn" (click)="goToSummaryReport()"><h5>{{'parentShare.viewReports' | translate}}</h5></button>
    </div>

    <div class="share-with-parents flex-cent flex-col">
        <h5 class="message-title">{{'parentShare.shareParents' | translate}}</h5>
        <div class="swp flex-cent flex-col">
            <div class="swp-card d-flex just-between align-center">
                <h5>{{'parentShare.results' | translate}}</h5>
                <button class="sendBtn" (click)="sendResults()"><img src="../../../../assets/img/sendBtn.png" alt=""></button>
            </div>
            <div class="swp-card d-flex just-between align-center">
                <h5>{{'parentShare.portfolio' | translate}}</h5>
                <button class="sendBtn" (click)="sendPortfolio()"><img src="../../../../assets/img/sendBtn.png" alt=""></button>
            </div>
        </div>
    </div>
</div>



<app-footer-navbar></app-footer-navbar>
