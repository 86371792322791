import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private alertSubject = new Subject<AlertMessage>();
  alert$ = this.alertSubject.asObservable();

  showAlert(header: string, message: string, type: 'success' | 'Note' | 'error' = 'success', confirmCallback?: () => void) {
    this.alertSubject.next({ header, message, type, confirmCallback });
  }

}

interface AlertMessage {
  header: string;
  message: string;
  type: 'success' | 'Note' | 'error';
  confirmCallback?: () => void;
}
